import axios from "axios";

class UploadFile {
  constructor() {
    this.token = localStorage.getItem("shipping-admin-token");
  }

  upload(endpoint, obj) {
    this.token = localStorage.getItem("shipping-admin-token");
    let formData = new FormData();
    Object.keys(obj).forEach(el=>{
      formData.append(`${el}`, obj[el]);
    });
    return axios.post(`${process.env.VUE_APP_BASE_URL}${endpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization":"Bearer ".concat(this.token),
      },
    });
  }
}

export default new UploadFile();
