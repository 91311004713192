<template>
<div class="row mb-4">
  <div class="col-lg-8 col-md-12 col-sm-12 px-4 table-responsive overflow-scroll">
    <table class="table table-bordered align-items-center table-sm mt-4">
      <thead>
      <tr>
        <td colspan="7">
          <h6>Tracking list</h6>
        </td>
      </tr>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Tracking</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Hour</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Subtitle</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Option</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in arrayTracking" :key="key">
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.day}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.date_tracking}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.hour}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.subtitle}}</p>
        </td>
        <td>
          <p class="text-xs font-weight-bold mb-0">{{item.description}}</p>
        </td>
        <td>
          <button class="btn btn-danger" @click="deleteTracking(item._id)">Delete</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-lg-4 col-md-12 col-sm-12 px-4">
    <label for="">Day</label>
    <input type="text" class="form-control" v-model="tracking.day" placeholder="Lunes">
    <label for="">Date Tracking</label>
    <input type="text" class="form-control" v-model="tracking.date_tracking" placeholder="1 Mayo 2023">
    <label for="">Hour</label>
    <input type="text" class="form-control" v-model="tracking.hour" placeholder="12:43 Hora local">
    <label for="">Title</label>
    <input type="text" class="form-control" v-model="tracking.title" placeholder="Envío entregado">
    <label for="">Subtitle</label>
    <input type="text" class="form-control" v-model="tracking.subtitle" placeholder="Área de Servicio: MIAMI FL - USA">
    <label for="">Description</label>
    <input type="text" class="form-control" v-model="tracking.description" placeholder="1Pieza JD01460010949167751">
    <button class="btn btn-success w-100 mt-4" @click="createTracking">Create tracking</button>
  </div>
</div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Tracking",
  data: () => ({
    user_id: null,
    tracking: {
      user: null,
      day: null,
      date_tracking: null,
      hour: null,
      title: null,
      subtitle: null,
      description: null,
    },
    arrayTracking: []
  }),
  mounted() {
    this.user_id = this.$route.query.id
this.getTracking()
  },
  methods: {
    async getTracking() {
      const {data}  = await get(`users/get-boarding/?id=${this.user_id}`)
      this.arrayTracking = data
    },
    async deleteTracking(id) {
      await post("users/delete-boarding",{id: id})
      await this.getTracking()
    },
    async createTracking(){
      this.tracking.user = this.user_id
      await post('users/create-tracking', this.tracking)
      await this.getTracking()
      this.tracking = {
        user: null,
        day: null,
        date_tracking: null,
        hour: null,
        title: null,
        subtitle: null,
        description: null,
      }
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
}
</script>

<style scoped>

</style>
