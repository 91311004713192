<template>
  <div class="w-100 mb-4">
    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6 class="text-uppercase" v-if="user">{{user.type}} details</h6>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col">
            <ul style="list-style: none" v-if="user">
              <li><span class="font-weight-bold">Name:</span>
                <input type="text" class="form-control" v-model="user.name">
              </li>
              <li><span class="font-weight-bold">Email:</span>
                <input type="text" class="form-control" v-model="user.email">
              </li>
              <li class="mt-2"><span class="font-weight-bold">Date Created</span>
              <p class="form-control">{{$filters.formatDate(user.created_at)}}</p>
              </li>
              <li><span class="font-weight-bold">Token:</span>
                <p type="text" class="form-control text-danger " style="font-size: 1.5rem">{{user.token}}</p>
              </li>
            </ul>
            <div class="w-100" v-if="$userCan(['administrator','supervisor_ftd','supervisor_retention', 'agent_retention'])">
              <button class="btn btn-success w-100" @click="updateUser">update information user</button>
            </div>
          </div>
          <div class="col">
            <label>Tracking number order</label>
            <input type="text" class="form-control" placeholder="5081509403" v-model="order.code">
            <label>Shipping status</label>
            <input type="text" class="form-control" placeholder="Envío entregado" v-model="order.status">
            <label>Shipping status Color</label>
            <select name="shipping_color" id="shipping_color" v-model="order.status_color" class="form-control">
              <option value="danger"><span class="text-danger">Error</span></option>
              <option value="warning"><span class="text-warning">Warning</span></option>
              <option value="info"><span class="text-info">Info</span></option>
              <option value="success"><span class="text-success">Success</span></option>
            </select>
            <label>Fecha completado</label>
            <input type="text" class="form-control" placeholder="01 Mayo 2023 12:43 Hora local, Área de servicio: MIAMI, FL -USA" v-model="order.date_completed">
            <label>Origin</label>
            <input type="text" class="form-control" placeholder="Área de Servicio: EINDHOVEN - IRLANDA" v-model="order.origin">
            <label>Step</label>
            <input type="text" class="form-control" placeholder="2" v-model="order.step">
            <label>Visible</label>
            <select name="order_visible" id="order_visible" class="form-control" v-model="order.visible">
              <option value="true">Visible</option>
              <option value="false">No Visible</option>
            </select>
            <button class="btn btn-success w-100 mt-4" @click="updateTracking">update tracking information</button>
          </div>
          <div class="col">
            <label>Destination name</label>
            <input type="text" class="form-control" placeholder="Destination name" v-model="order.destination.name">
            <label>Destination identification</label>
            <input type="text" class="form-control" placeholder="Destination identification" v-model="order.destination.identification">
            <label>Destination country</label>
            <input type="text" class="form-control" placeholder="Destination country" v-model="order.destination.country">
            <label>Destination Address</label>
            <input type="text" class="form-control" placeholder="C MIGUEL HIDALGO SN LOC PALMILLAS 40341 BUENAVISTA DE CUELLAR, GRO" v-model="order.destination.address">
            <hr>
            <label>Boarding text</label>
            <input type="text" class="form-control" placeholder="Boarding text" v-model="order.embarque.receipt">
            <label>Boarding date</label>
            <input type="text" class="form-control" placeholder="Boarding date" v-model="order.embarque.date">
            <label>Quantity</label>
            <input type="text" class="form-control" placeholder="Quantity" v-model="order.embarque.quantity">
            <label>Boarding number</label>
            <input type="text" class="form-control" placeholder="Boarding number" v-model="order.embarque.number_order">
          </div>
        </div>
      </div>

    </div>
    <div class="card mb4">
     <div class="overflow-hidden">
       <ul class="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
         <li class="nav-item" role="presentation">
           <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">PAYMENTS</button>
         </li>
         <li class="nav-item" role="presentation">
           <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">TRACKING</button>
         </li>
       </ul>
       <div class="tab-content" id="pills-tabContent">
         <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
           <Payments/>
         </div>
         <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
           <Tracking/>
         </div>
       </div>
     </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";
import Payments from "@/views/Users/Payments.vue";
import Tracking from "@/views/Users/Tracking.vue";
export default {
  name: "index",
  components: {
    Payments,
    Tracking
  },
  data: () => ({
    user_id: null,
    user: null,
    order: {
      visible: true,
      user: null,
      code: null,
      status: null,
      status_color: null,
      date_completed: null,
      origin: null,
      step: null,
      destination: {
        name: null,
        identification: null,
        country: null,
        address: null
      },
      embarque:{
        receipt: null,
        date: null,
        quantity: null,
        number_order: null
      },
    },
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getProfile()
    this.getTracking()
  },
  methods: {
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`)
      data.password = ''
      this.user = data
    },

    async getTracking(){
      const {data} = await get(`users/get-tracking?id=${this.user_id}`)
      if(data){
        this.order = data
      }
    },
    async updateUser() {
      await post('users/update', {...this.user, _id: this.user_id})
      await this.getProfile()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    async updateTracking() {
      this.order.user = this.user_id
      await post('users/update-tracking', {...this.order})
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
      await this.getTracking()
    },

  }
}
</script>

<style scoped>

</style>
