<template>
  <div class="row">
    <div class="col-lg-8 col-md-12 col-sm-12 px-4 table-responsive overflow-scroll">
      <table class="table table-bordered align-items-center table-sm mt-4">
        <thead>
        <tr>
          <td colspan="4">
            <h6>Payment list</h6>
          </td>
        </tr>
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Logo</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Information</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in arrayPayments" :key="key">
          <td>
            <p class="text-xs font-weight-bold mb-0">{{item.title}}</p>
          </td>
          <td>
            <img class="logo" :src="item.logo_url" style="width: 150px; height: 150px"/>
          </td>
          <td>
           <div v-html="item.description"></div>
          </td>
          <td>
            <button class="btn btn-danger" @click="deletePayment(item._id)">Delete</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 px-4">
      <label for="">Titulo</label>
      <input type="text" class="form-control" v-model="payment.title">
      <label for="">logo</label>
      <input type="file" class="form-control mt-2" @change="selectImage">
      <label for="">Information</label>
      <ckeditor v-model="payment.description"></ckeditor>
      <button class="btn btn-success w-100 mt-4" @click="createPayments">Create payments</button>
    </div>
  </div>
</template>

<script>
import uploadFile from "@/utils/uploadFile";
import {get, post} from "@/utils/httpRequest";

export default {
  name: "Payments",
  mounted() {
    this.user_id = this.$route.query.id
    this.getPayments()
  },
  data: () => ({
    user_id: null,
    arrayPayments: [],
    url_logo: '',
    payment: {
      title: '',
      logo_url: '',
      description: ''
    }
  }),
  methods: {
    async createPayments(){
      if(this.url_logo!== '' && this.payment.title!== '' && this.payment.description!== ''){
        const {data} = await uploadFile.upload('users/upload-logo',{file: this.url_logo, _id: this.user_id})
        this.payment.logo_url = data
        await post("users/create-payment", {...this.payment, user: this.user_id})
        await this.getPayments()
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        this.url_logo = ''
        this.payment.description = ''
        this.payment.title = ''
        this.payment.logo_url = ''
      }
    },
    async selectImage(e){
      this.url_logo = e.target.files[0]
    },
    async getPayments(){
      const {data} = await get(`users/get-payments?id=${this.user_id}`)
      this.arrayPayments = data
    },
    async deletePayment(_id){
      await post("users/delete-payment", {_id: _id})
      await this.getPayments()
    }
  }
}
</script>

<style scoped>

</style>
