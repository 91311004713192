<template>
  <div class="card">
    <div class="card-header">
      <h6>Create new user with access in the platform</h6>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col">
          <label>Name</label>
          <input type="text" class="form-control" v-model="form.name">
          <label>Email</label>
          <input type="text" class="form-control" v-model="form.email">
        </div>
        <div class="col">
          <label>Token (user id)</label>
          <input type="text" class="form-control" v-model="form.token">
          <button  class="btn bg-gradient-success mt-4 w-100" @click="create"> Create new user</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {post} from "@/utils/httpRequest";

export default {
  name: "index",
  data: () => ({
    form: {
      name: '',
      email: '',
      password: 'Developer2023*',
      type: 'client',
      token: '',
    }
  }),
  methods: {
    async create(){
      if(this.form.name !== '' && this.form.email !== '' && this.form.token !== ''){
        this.form.token = this.form.token.trim()
        const {data} = await post('users/create', this.form)
        if(data === 200){
          this.$swal({
            html: 'Process successfully',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.$router.push('users')
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 767px) {
  .margin_top_mobile {
    margin-top: 1.5rem !important;
  }
}
</style>
